import { v4 as uuidv4 } from "uuid";
import { Button, Dropdown, Upload, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faIndustry,
  faCalculator,
  faChartPie,
  faProjectDiagram,
  faSquareCheck,
  faSeedling,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {
  // 產出報表
  GETLCADATAREPORT_API,
  GETLCADATAREPORT_III_API,
  GETLCALISTREPORT_API,
  GETLCAREPORT_API,
  GETLCARISKASSESSMENTREPORT_API,
  GETALLREPORT_API,
  GETMERGEDLCALISTREPORT_API,
  GETMERGEDLCAREPORT_API,
  VERIFYORGANIZATION_API,
  UPLOAD_REFERENCE_API,
  UPLOAD_GREENWORKFILE_API,
  GETLCADETAIL_API,
  UPLOAD_REPORT_API,
  GETEXPORTREPORT_API,
} from "@CON";
import {
  downloadGreenhouseGasInventorySample,
  downloadReferenceFile,
  messageGenerator,
  downloadLCA,
} from "@UTIL";
import { store, getLCA } from "@STORE";

const GreenhouseDropdown = ({ disabled, setLoading }) => {
  const { t } = useTranslation();
  const greenhouseDropdownItems = [
    {
      key: "sample",
      label: (
        <Button
          key="sample"
          type="primary"
          size="large"
          className="w-100"
          onClick={() => {
            downloadGreenhouseGasInventorySample(
              "/Browser_Local/WebFilesRoot/溫室氣體盤查作業規範.docx"
            );
          }}
        >
          {t("Buttons.Template")}
        </Button>
      ),
    },
    {
      key: "upload",
      label: (
        <Upload
          key="upload"
          className="GreenhouseUploadBtn"
          name="GreenhouseGasInventoryWorkFile"
          action={(file) => {
            return Promise.resolve(UPLOAD_REFERENCE_API);
          }}
          showUploadList={false}
          onChange={async (e) => {
            const {
              project: { LCAInfo },
            } = store.getState();
            if (e?.file?.status && e.file?.status === "error") {
              messageGenerator({ content: t("MessageText.Upload failed") });
            } else if (e && e.file?.status === "done") {
              const res = await axios.post(UPLOAD_GREENWORKFILE_API, {
                LCAID: LCAInfo.ID,
                GreenhouseGasInventoryWorkFileUrl: e.file?.response[0],
              });
              if (res.result === "success") {
                const LCADetail = await axios.get(
                  `${GETLCADETAIL_API}/${LCAInfo?.ID}`
                );
                const { LCAobj } = LCADetail;
                store.dispatch(getLCA(LCAobj));
                messageGenerator({
                  result: "success",
                  content: t("MessageText.Upload successful"),
                });
              } else {
                messageGenerator({ content: t("MessageText.Upload failed") });
              }
            }
          }}
        >
          <Button
            key="upload"
            type="primary"
            size="large"
            className="w-100"
            disabled={store.getState().project.LCADisabled}
          >
            {t("Buttons.Upload")}
          </Button>
        </Upload>
      ),
    },
    {
      key: "download",
      label: (
        <Button
          type="primary"
          size="large"
          className="w-100"
          key="download"
          onClick={async () => {
            setLoading(true);
            const {
              project: { LCAInfo },
            } = store.getState();

            if (LCAInfo?.GreenhouseGasInventoryWorkFileUrl) {
              downloadReferenceFile([
                LCAInfo?.GreenhouseGasInventoryWorkFileUrl,
              ]);
            }
            setLoading(false);
          }}
        >
          {t("Buttons.File")}
        </Button>
      ),
    },
  ];
  return (
    <Dropdown
      disabled={disabled}
      menu={{
        items: greenhouseDropdownItems,
      }}
      trigger={["click"]}
      placement="bottom"
      arrow={{
        pointAtCenter: true,
      }}
    >
      <button className="btn bg-info text-white rounded-3 py-3 my-1 fs-6">
        {t("Project.Greenhouse gas management procedure")}
      </button>
    </Dropdown>
  );
};

const ReportDropdown = ({ disabled, setLoading }) => {
  const { t } = useTranslation();
  const reportDropdownItems = [
    {
      key: "sample",
      label: (
        <Button
          key="sample"
          type="primary"
          size="large"
          className="w-100"
          onClick={() => {
            downloadGreenhouseGasInventorySample(
              "/Browser_Local/WebFilesRoot/組織型報告書.docx"
            );
          }}
        >
          {t("Buttons.Template")}
        </Button>
      ),
    },
    {
      key: "upload",
      label: (
        <Upload
          key="upload"
          className="GreenhouseUploadBtn"
          name="GreenhouseGasInventoryWorkFile"
          action={(file) => {
            return Promise.resolve(UPLOAD_REPORT_API);
          }}
          showUploadList={false}
          onChange={async (e) => {
            if (e?.file?.status && e.file?.status === "error") {
              messageGenerator({ content: t("MessageText.Upload failed") });
            } else if (e && e.file?.status === "done") {
              if (e?.file?.response.Success) {
                messageGenerator({
                  result: "success",
                  content: t("MessageText.Upload successful"),
                });
              } else {
                messageGenerator({ content: t("MessageText.Upload failed") });
              }
            }
          }}
        >
          <Tooltip title={t("FormMsg.FileExtensionsDOC")}>
            <Button
              key="upload"
              type="primary"
              size="large"
              className="w-100"
              disabled={store.getState().project.LCADisabled}
            >
              {t("Buttons.Upload")}
            </Button>
          </Tooltip>
        </Upload>
      ),
    },
    {
      key: "download",
      label: (
        <Button
          type="primary"
          size="large"
          className="w-100"
          key="download"
          onClick={async () => {
            setLoading(true);
            const {
              project: {
                LCAInfo: { ID },
              },
            } = store.getState();

            const res = await axios.post(`${GETLCAREPORT_API}/${ID}`);
            if (res.split(",").length) {
              downloadLCA(res.split(","));
              messageGenerator({
                result: "success",
                content: t("MessageText.Download successful"),
              });
            } else {
              messageGenerator({
                result: "error",
                content: t("MessageText.Download failed"),
              });
            }
            setLoading(false);
          }}
        >
          {t("Buttons.File")}
        </Button>
      ),
    },
  ];

  return (
    <Dropdown
      disabled={disabled}
      menu={{
        items: reportDropdownItems,
      }}
      trigger={["click"]}
      placement="bottomLeft"
      arrow={{
        pointAtCenter: true,
      }}
    >
      <button className="btn bg-info text-white rounded-3 py-3 my-1 fs-6">
        {t("Project.Report")}
      </button>
    </Dropdown>
  );
};

const projectFlowList = (ID = "",SiteUser) => [
  {
    key: uuidv4(),
    category: "Boundary setting",
    icon: <FontAwesomeIcon icon={faIndustry} size="2x" />,
    subCategory: [
      {
        subKey: "boundary",
        text: "Boundary setting",
        redirect: "/project/boundary",
        isShow: true, // 用來解決不再功能列表上但須顯示的問題
      },
      {
        subKey: "sbtisetting",
        text: "sbtisetting",
        redirect: "/project/boundary/sbtisetting",
      },
      {
        subKey: "project-management",
        text: "project-management",
        redirect: "/project/boundary/project-management",
        isShow: true,
      },
    ],
  },
  {
    key: uuidv4(),
    category: "Emissions calculation",
    icon: <FontAwesomeIcon icon={faCalculator} size="2x" />,
    subCategory: [
      {
        subKey: "calculate",
        text: "Emission data input",
        redirect: "/project/calculate",
        isShow: true,
      },
      {
        subKey: "risk",
        text: "Significant analysis",
        redirect: `/project/risk/${ID ?? ""}`,
      },
    ],
  },
  {
    key: uuidv4(),
    category: "Inventory results",
    icon: <FontAwesomeIcon icon={faChartPie} size="2x" />,
    subCategory: [
      // { subKey: uuidv4(), text: "溫室氣體排放統計表", redirect: "" },
      {
        subKey: "analyse",
        text: "Analyse",
        redirect: "/project/analyse",
      },
      {
        subKey: "compare",
        text: "compare",
        redirect: "/project/compare",
      },
      {
        subKey: "LCADataReport", // 盤查表
        text: "Inventory checklist",
        redirect: "",
        downloadApi: SiteUser === "III" ? GETLCADATAREPORT_III_API : GETLCADATAREPORT_API,
      },
      {
        subKey: "LCARiskAssessmentReport", // 風險評估報表
        text: "Risk assessment report",
        redirect: "",
        downloadApi: GETLCARISKASSESSMENTREPORT_API,
      },
      {
        // 資策會用
        subKey: "ExportReport", // 資策會報表
        text: "ExportReport",
        redirect: "",
        downloadApi: GETEXPORTREPORT_API,
        auth: "III",
      },
      {
        subKey: "LCAListReport", // 盤查清冊
        text: "Inventory list",
        redirect: "",
        downloadApi: GETLCALISTREPORT_API,
      },
      {
        subKey: "LCAReport", // 溫室氣體報告書
        text: <ReportDropdown />,
        Node: ReportDropdown,
        isReactNode: true,
      },
      // {
      //   subKey: "LCAReport",
      //   text: "Inventory report", // 碳盤查報告書
      //   redirect: "",
      //   downloadApi: GETLCAREPORT_API,
      // },
      {
        subKey: "allReport",
        text: "All reports", // 全部報表
        redirect: "",
        downloadApi: GETALLREPORT_API,
      },
      {
        subKey: "mergedLCAListReport",
        text: "Combine inventory lists", // 合併清冊
        redirect: "",
        downloadApi: GETMERGEDLCALISTREPORT_API,
      },
      {
        subKey: "mergedLCAReport", // 合併報告書
        text: "Combine inventory report",
        redirect: "",
        downloadApi: GETMERGEDLCAREPORT_API,
      },
      {
        subKey: "ghgreport",
        text: "GHGreport",
        redirect: "/project/ghgreport",
      },
    ],
  },
  {
    key: uuidv4(),
    category: "Standard process",
    icon: <FontAwesomeIcon icon={faProjectDiagram} size="2x" />,
    subCategory: [
      {
        subKey: "GreenhouseGasInventory", // 溫室氣體管理程序
        text: <GreenhouseDropdown />,
        Node: GreenhouseDropdown,
        isReactNode: true,
      },
    ],
  },
  {
    key: uuidv4(),
    category: "Verification",
    icon: <FontAwesomeIcon icon={faSquareCheck} size="2x" />,
    subCategory: [
      {
        subKey: "verification", // 內部驗證
        text: "Internal verification",
        redirect: "/project/verification",
      },
      {
        subKey: "verifyOrganization",
        text: "External verification",
        redirect: "",
        verifyApi: VERIFYORGANIZATION_API,
      },
    ],
  },
  {
    key: uuidv4(),
    category: "Net-zero roadmap planning",
    icon: <FontAwesomeIcon icon={faSeedling} size="2x" />,
    subCategory: [
      {
        subKey: "sbtichart",
        text: "Establish a net-zero roadmap",
        redirect: "/project/sbtichart",
      },
      {
        subKey: "carboncredit",
        text: "Carbon credit",
        redirect: "/project/CarbonCredit",
      },
      // {
      //   subKey: "GHG deduction planning",
      //   text: "GHG deduction planning",
      //   redirect: "",
      // },
    ],
  },
];

export default projectFlowList;
