import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfigProvider, Typography, Button } from "antd";
import {
  LockOutlined,
  SwapOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { customerThemeToken } from "@/customerTheme";
import logo from "@ASSET/logo.svg";
import LoginForm from "@COM/login/LoginForm";
import { VERIFY_API, VERIFY_API_URL } from "@CON";
import { messageGenerator } from "@UTIL";
import VersionFooter from "@COM/VersionFooter";
import LoginIcon from "@ASSET/loginIcon.svg";
import Language from "@COM/Language";

function Login() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();

  // 信箱驗證後，會需要先判斷是否驗證成功
  const verifyEmail = async (search) => {
    const { result = "error", errMsg } = await axios.get(
      `${VERIFY_API}${search}`
    );
    if (result === "success") {
      messageGenerator({ result, content: t("MessageText.Verification successful") });
    } else {
      messageGenerator({ result, content: errMsg ?? t("MessageText.Verification failed") });
    }
  };

  useEffect(() => {
    if (search) {
      verifyEmail(search);
    }
  }, []);

  return (
    <ConfigProvider  csp={{ nonce: '2726c7f26c' }}
      theme={{
        token: customerThemeToken,
      }}
    >
      <Language>
        <section
          className="w-100 min-vh-100 d-flex flex-column justify-content-center align-items-center"
          style={{ backgroundColor: "#F0F9FF" }}
        >
          {/* <img src={logo} alt="logo" className="w-25 h-25 my-4" /> */}
          <div
            className="card overflow-hidden"
            style={{
              minWidth: "280px",
              maxWidth: "35%",
            }}
          >
            <div className="bg-primary bg-soft">
              <div className="row">
                <div className="col-8">
                  <div className="text-primary p-4">
                    <Typography.Title level={3} style={{ color: "#556EE6" }}>
                      {t("User.Welcome")}
                    </Typography.Title>
                    <Typography.Text className="text-muted">
                      {t("User.Login")}
                    </Typography.Text>
                  </div>
                </div>
                <div className="col-4 align-self-center pe-4">
                  <img src={LoginIcon} alt="loginIcon" className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="card-body d-flex flex-column align-items-center px-4">
              <LoginForm />
              <div className="w-100 d-flex justify-content-between">
                <Button
                  type="link"
                  size="large"
                  icon={<LockOutlined />}
                  onClick={() => navigate("/recovery")}
                  className="d-flex align-items-center btn-link text-dark mt-3 p-0"
                >
                  {t("User.Forget")}
                </Button>
                {/* <Button
                  type="link"
                  size="large"
                  icon={<SwapOutlined />}
                  href={VERIFY_API_URL}
                  className="d-flex align-items-center btn-link text-dark mt-3 p-0"
                >
                  驗證人員點此進入
                </Button> */}
              </div>
            </div>
          </div>
          {/* <div className="w-25 d-flex justify-content-end">
            <Button
              type="link"
              size="large"
              icon={<DoubleRightOutlined />}
              href="https://demo.netzero.com.tw"
              className="d-flex align-items-center btn-link text-dark p-0 me-4"
            >
              {t("User.OldVersion")}
            </Button>
          </div> */}
          <VersionFooter />
        </section>
      </Language>
    </ConfigProvider>
  );
}

export default Login;
